import React, { useState, useEffect } from "react";
import styled from "styled-components";
import timeBlockService from "../../../utils/api/v1/timeblockService";
import availabilityService from "../../../utils/api/v1/availabilityService";
import {
  groupTimeBlocks,
} from "../../../utils/timeblocks/group";
import { isEqual, differenceWith } from "lodash";

// Types
import {
  TimeBlock,
  GroupedTimeBlocks,
} from "../../../utils/interfaces/Timeblock";

// Components
import LoadingError from "../../../components/Loaders/LoadingError";
import PrivateRoute from "../../../components/Authentication/PrivateRoute";
import InnerContainer from "../../../components/Containers/InnerContainer";
import OutterContainer from "../../../components/Containers/OutterContainer";
import DisplayTimeBlock from "../../../components/Timeblocks/DisplayTimeBlock";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddTimeblock from "../../../components/Timeblocks/AddTimeBlock";

const SAddCircleIcon = styled(AddCircleIcon)`
  margin-left: 15px;
  cursor: pointer;
`;

const TitleWrapper = styled.h1`
  margin: 20px 0px;
`;

interface IProps {
  id: string;
}

const Schedule = (props: IProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [activeTimeBlocks, setActiveTimeBlocks] = useState<GroupedTimeBlocks>(
    {}
  );
  const [rawActiveTimeBlocks, setRawActiveTimeBlocks] = useState<TimeBlock[]>(
    []
  );
  const [allTimeBlocks, setAllTimeBlocks] = useState<TimeBlock[]>([]);
  const [showAdd, setShowAdd] = useState<boolean>(false);

  const fetchTimeblocks = async (): Promise<void> => {
    setLoading(true);
    try {
      const [actTbReq, allTbReq] = await Promise.all([
        availabilityService.labService.getTimeBlocks(props.id),
        timeBlockService.fetchAllTimeBlocks({ laboratory_id: props.id }),
      ]);
      const actTbs = actTbReq.data.data;
      const allTbs = allTbReq.data.data;

      setActiveTimeBlocks(
        groupTimeBlocks(actTbs.timeblocks) as GroupedTimeBlocks
      );
      setRawActiveTimeBlocks(actTbs.timeblocks);
      setAllTimeBlocks(allTbs);
    } catch (err: any) {
      setError(err.response.data.error);
      console.log(err);
    }
    setLoading(false);
  };

  const updateActiveTimeBlocks = (newTimeblocks: TimeBlock[]) => {
    const oldTimeblocks = [...rawActiveTimeBlocks];
    const timeblocks = oldTimeblocks.concat(newTimeblocks);
    setRawActiveTimeBlocks(timeblocks);
    setActiveTimeBlocks(groupTimeBlocks(timeblocks) as GroupedTimeBlocks);
  };

  const deleteActiveTimeBlocks = (deletedTimeblocks: TimeBlock[]): void => {
    const oldTimeblocks = [...rawActiveTimeBlocks];
    const newTimeblocks = differenceWith(
      oldTimeblocks,
      deletedTimeblocks,
      isEqual
    );
    setRawActiveTimeBlocks(newTimeblocks);
    setActiveTimeBlocks(groupTimeBlocks(newTimeblocks) as GroupedTimeBlocks);
  };

  useEffect((): void => {
    fetchTimeblocks();
  }, []);

  return (
    <PrivateRoute>
      <h1>Horarios laboratorio</h1>
      <OutterContainer>
        <InnerContainer backgroundColor="white" padding="20px 40px">
          <LoadingError loading={loading} error={error} />
          {!loading && !error && (
            <>
              <TitleWrapper>
                Horarios actuales
                {!showAdd && (
                  <SAddCircleIcon
                    onClick={() => {
                      setShowAdd(true);
                    }}
                  />
                )}
              </TitleWrapper>
              {showAdd && (
                <AddTimeblock
                  onCancel={() => {
                    setShowAdd(false);
                  }}
                  timeblocks={allTimeBlocks}
                  currentTimeblocks={rawActiveTimeBlocks}
                  updateTimeblocks={updateActiveTimeBlocks}
                  id={props.id}
                  type="lab"
                />
              )}
              <DisplayTimeBlock
                timeblocks={activeTimeBlocks}
                deleteBlocks={deleteActiveTimeBlocks}
                isEditable
                id={props.id}
                type="lab"
              />
            </>
          )}
        </InnerContainer>
      </OutterContainer>
    </PrivateRoute>
  );
};

export default Schedule;
